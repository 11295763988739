import React, { Component } from 'react';
import axios from 'axios';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import AuthContext from '../AuthContext';


class ClientProjectsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectNames: []
        };
    }

    static contextType = AuthContext;

    componentDidMount() {
        const { userId } = this.context;
        const API_URL = process.env.REACT_APP_API_URL;
        axios.post(`${API_URL}/Client/getClientProjects`, { userId: userId })
            .then(response => {
                if (response.data.result) {
                    this.setState({ projectNames: response.data.projectNames });
                }
            })
            .catch(error => {
            });
    }
    render() {
        const { projectNames } = this.state; // Access state here

        return (
            <Row>
                {projectNames.map((name, index) => (
                    <Col xs={12} md={6} lg={3} className="my-3">
                        <NavLink tag={Link} to="/servers">
                            <div className="homeBox">
                                <Row className="h-100 align-items-center">
                                    <Col className="text-center">
                                        <p className="mb-0">
                                            <span className="title">{name}</span>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </NavLink>
                    </Col>
                ))}
            </Row>
        );
    }
}

export default ClientProjectsList;