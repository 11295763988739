import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col, Container, Accordion, Badge, Spinner } from 'react-bootstrap';
import AuthContext from '../AuthContext';
import BackToDash from '../BackToDash';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';


class Servers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,

            lastUpdated: '',

            byServerSites: [],
            byServerName: "",
            byServerSitesTotal: 0,
            byServerSitesOnline: 0,
            byServerSitesOffline: 0,

            wuthServerSites: [],
            wuthServerName: "",
            wuthServerSitesTotal: 0,
            wuthServerSitesOnline: 0,
            wuthServerSitesOffline: 0,

            sharedServerSites: [],
            sharedServerName: "",
            sharedServerSitesTotal: 0,
            sharedServerSitesOnline: 0,
            sharedServerSitesOffline: 0,
        };
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.fetchServerStatus();
    }

    getBaseUrl = (url) => {
        const parsedUrl = new URL(url);
        return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
    }

    fetchServerStatus = () => {
        this.setState({ isLoading: true, });
        const API_URL = process.env.REACT_APP_API_URL;
        axios.get(`${API_URL}/Developer/getServerStatus`)
            .then(response => {
                this.setState({ isLoading: false, });

                if (response.data) {
                    const lastUpdated = response.data[0].lastUpdated;

                    const byServerSites = response.data[0].websites;
                    const byServerName = response.data[0].serverName;
                    const totalBySiteCount = byServerSites.length;
                    const onlineBySiteCount = byServerSites.filter(website => website.websiteStatus).length;
                    const offlineBySiteCount = totalBySiteCount - onlineBySiteCount;

                    const wuthServerSites = response.data[1].websites;
                    const wuthServerName = response.data[1].serverName;
                    const totalWuthSiteCount = wuthServerSites.length;
                    const onlineWuthSiteCount = wuthServerSites.filter(website => website.websiteStatus).length;
                    const offlineWuthSiteCount = totalWuthSiteCount - onlineWuthSiteCount;

                    const sharedServerSites = response.data[2].websites;
                    const sharedServerName = response.data[2].serverName;
                    const totalSharedSiteCount = sharedServerSites.length;
                    const onlineSharedSiteCount = sharedServerSites.filter(website => website.websiteStatus).length;
                    const offlineSharedSiteCount = totalSharedSiteCount - onlineSharedSiteCount;

                    this.setState({
                        lastUpdated: lastUpdated,

                        byServerSites: byServerSites,
                        byServerName: byServerName,
                        byServerSitesTotal: totalBySiteCount,
                        byServerSitesOnline: onlineBySiteCount,
                        byServerSitesOffline: offlineBySiteCount,

                        wuthServerSites: wuthServerSites,
                        wuthServerName: wuthServerName,
                        wuthServerSitesTotal: totalWuthSiteCount,
                        wuthServerSitesOnline: onlineWuthSiteCount,
                        wuthServerSitesOffline: offlineWuthSiteCount,

                        sharedServerSites: sharedServerSites,
                        sharedServerName: sharedServerName,
                        sharedServerSitesTotal: totalSharedSiteCount,
                        sharedServerSitesOnline: onlineSharedSiteCount,
                        sharedServerSitesOffline: offlineSharedSiteCount,

                    });
                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
            });
    }

    renderByServerStatus() {



        return this.state.byServerSites.map((byServerWebsite, index1) => (
            <Dropdown as={NavItem} className="my-3" key={index1}>
                <Dropdown.Toggle as={NavLink}>{byServerWebsite.websiteName} {byServerWebsite.websiteStatus ? <Badge bg="success">Online</Badge> : <Badge bg="danger">Offline</Badge>}</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <p>Status: {byServerWebsite.websiteStatus ? <Badge bg="success">Online</Badge> : <Badge bg="danger">Offline</Badge>}</p>
                        <p>Domain: {this.getBaseUrl(byServerWebsite.websiteUrl)}</p>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        ));
    }
    renderWuthServerStatus() {
        return this.state.wuthServerSites.map((wuthServerWebsite, index2) => (
            <Dropdown as={NavItem} className="my-3" key={index2}>
                <Dropdown.Toggle as={NavLink}>{wuthServerWebsite.websiteName} {wuthServerWebsite.websiteStatus ? <Badge bg="success">Online</Badge> : <Badge bg="danger">Offline</Badge>}</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <p>Status: {wuthServerWebsite.websiteStatus ? <Badge bg="success">Online</Badge> : <Badge bg="danger">Offline</Badge>}</p>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        ));
    }
    renderSharedServerStatus() {
        return this.state.sharedServerSites.map((sharedServerWebsite, index3) => (
            <Dropdown as={NavItem} className="my-3" key={index3}>
                <Dropdown.Toggle as={NavLink}>{sharedServerWebsite.websiteName} {sharedServerWebsite.websiteStatus ? <Badge bg="success">Online</Badge> : <Badge bg="danger">Offline</Badge>}</Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <p>Status: {sharedServerWebsite.websiteStatus ? <Badge bg="success">Online</Badge> : <Badge bg="danger">Offline</Badge>}</p>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        ));
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="homePage">
                    <Container className="py-3">
                        <Row className="mt-3 justify-content-center align-items-center">
                            <Col className="text-center text-white">
                                <Spinner /> <span>Checking Website Status', Please wait...</span>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )

        }
        return (
            <div className="homePage">
                <Container className="py-3">
                    <Row>
                        <Col>
                            <h1>Server/Website Status Checker</h1>
                            <p>Here you can see a list of our servers and the status of our websites that we currently monitor. When a website goes down, the Hub will send an email notification to digital@icecreates.com</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <button className="btn btn-blue-solid" onClick={this.fetchServerStatus}>Refresh Website Status</button>
                            <p className="mt-2 text-white">Last Updated {new Date(this.state.lastUpdated).toLocaleString()}</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Accordion>
                                <Accordion.Item eventKey="0" className="my-3">
                                    <Accordion.Header>{this.state.byServerName}</Accordion.Header>
                                    <Accordion.Body>

                                        <p>Some information about the server here</p>

                                        <h4>Websites <Badge bg="secondary">{this.state.byServerSitesTotal}</Badge> <Badge bg="success">{this.state.byServerSitesOnline}</Badge> <Badge bg="danger">{this.state.byServerSitesOffline}</Badge></h4>

                                        {this.renderByServerStatus()}


                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="my-3">
                                    <Accordion.Header>{this.state.wuthServerName}</Accordion.Header>
                                    <Accordion.Body>

                                        <p>Some information about the server here</p>

                                        <h4>Websites <Badge bg="secondary">{this.state.wuthServerSitesTotal}</Badge> <Badge bg="success">{this.state.wuthServerSitesOnline}</Badge> <Badge bg="danger">{this.state.wuthServerSitesOffline}</Badge></h4>

                                        {this.renderWuthServerStatus()}


                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className="my-3">
                                    <Accordion.Header>{this.state.sharedServerName}</Accordion.Header>
                                    <Accordion.Body>

                                        <p>Some information about the server here</p>

                                        <h4>Websites <Badge bg="secondary">{this.state.sharedServerSitesTotal}</Badge> <Badge bg="success">{this.state.sharedServerSitesOnline}</Badge> <Badge bg="danger">{this.state.sharedServerSitesOffline}</Badge></h4>

                                        {this.renderSharedServerStatus()}


                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Servers;