import React, { Component } from 'react';
import SignalRManager from './SignalRManager';
import BackToDash from '../BackToDash';
import axios from 'axios';
import { Form, Button, Row, Col, Container, InputGroup, FormControl, Badge } from 'react-bootstrap';
import AuthContext from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPenToSquare } from '@fortawesome/free-solid-svg-icons';



class Chat extends Component {
    constructor(props) {
        super(props);
        this.scrollableDivRef = React.createRef();
        this.signalRManager = new SignalRManager('/chatHub');
        this.state = {
            messages: [],
            newMessage: '',
            chatData: [],
            currentlyOpenChat: null,
            activeChat: false,
            lastMessageSentTS: null,
            lastMessageSentGroupId: null,
            lastMessageSentUserId: null,
            chatIdsWithNewMessages: []
        };
    }

    handleChatClick = (chatId) => {
        this.setState({ activeChat: chatId });
    }

    static contextType = AuthContext;

    componentDidMount() {
        this.signalRManager.start();

        this.signalRManager.on('ReceiveMessage', this.handleReceiveMessage);

        const API_URL = process.env.REACT_APP_API_URL;
        axios.post(`${API_URL}/Chat/GetUsersChatGroups`, {
            userId: this.context.userId
        })
            .then(response => {
                if (response.data.result) {
                    this.setState({ chatData: response.data.chatData });
                }
            })
            .catch(error => {
            });


    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentWillUnmount() {
        this.signalRManager.off('ReceiveMessage', this.handleReceiveMessage);
        this.signalRManager.stop();
    }

    handleReceiveMessage = (userId, user, groupId, message, timestamp) => {
        this.setState(prevState => ({
            messages: [...prevState.messages, { userId, user, groupId, message, timestamp }]
        }));
        if (this.state.currentlyOpenChat != this.state.activeChat) {
            if (!this.state.chatIdsWithNewMessages.includes(groupId)) {
                this.setState(prevState => ({
                    chatIdsWithNewMessages: [...prevState.chatIdsWithNewMessages, groupId]
                }));
            }
        }
    }

    scrollToBottom = () => {
        if (this.scrollableDivRef.current) {
            this.scrollableDivRef.current.scrollTop = this.scrollableDivRef.current.scrollHeight;
        }
    };

    sendMessage = () => {
        const { newMessage } = this.state;
        if (newMessage && newMessage.trim()) {
            const API_URL = process.env.REACT_APP_API_URL;
            axios.post(`${API_URL}/Chat/SendMessage`, {
                userId: this.context.userId,
                messageText: newMessage,
                chatGroupId: this.state.currentlyOpenChat,
                senderName: this.context.loggedInName
            })
                .then(response => {
                })
                .catch(error => {
                });
            const currentDateTime = new Date();
            this.setState({ lastMessageSentTS: currentDateTime });
            this.setState({ lastMessageSentGroupId: this.state.currentlyOpenChat });
            this.setState({ lastMessageSentUserId: this.context.userId });

            this.signalRManager.invoke('SendMessage', this.context.userId, this.context.loggedInName, this.state.currentlyOpenChat, newMessage, currentDateTime);
        }
        this.setState({ newMessage: '' });
    }

    openChat = (chatId) => {
        this.setState({ currentlyOpenChat: chatId });
    }

    render() {

        const { isLoggedIn, loggedInName, userType, userId } = this.context;

        if (!isLoggedIn && (userType != "DEVELOPER" && userType != "ADMIN")) {
            window.location.href = '/';
        }

        if (!isLoggedIn) {
            return <div>Checking authentication...</div>;
        }

        const formatDateTime = (dateTimeString) => {
            const dateTime = new Date(dateTimeString);
            const today = new Date();
            const isToday = dateTime.toDateString() === today.toDateString();

            const options = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };

            if (isToday) {
                return dateTime.toLocaleTimeString('en-US', options);
            } else {
                const dayOptions = { weekday: 'long' };
                const dayName = dateTime.toLocaleDateString('en-US', dayOptions);
                const timeString = dateTime.toLocaleTimeString('en-US', options);
                return `${dayName} ${timeString}`;
            }
        };

        function getInitials(senderName) {
            const names = senderName.split(' ');
            const initials = names.map(name => name[0]);
            return initials.join('');
        }

        return (
            <div className="homePage">
                <Container className="py-3">
                    <Row className="chatScreen h-100">
                        <Col xs={3}>
                            <Row className="chatScreen__side h-100">
                                <Col xs={12} className="chatScreen__side__head">
                                    <div className="p-3 px-4 text-white">
                                        <span className="me-2">Live Chat!</span>
                                        <span>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={12} className="chatScreen__side__chats p-0">
                                    {this.state.chatData.map((chat, index) => (
                                        <a key={chat.id} href="#" className={`text-decoration-none text-white ${this.state.activeChat === chat.id ? "active" : ""}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.openChat(chat.id);
                                                this.handleChatClick(chat.id);
                                            }}>
                                            <div className={`p-4 chatScreen__side__chats__chat ${this.state.activeChat === chat.id ? " active" : ""}`} onClick={(e) => {
                                                e.preventDefault();
                                                this.handleChatClick(chat.id);
                                            }}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Row>
                                                            <Col xs={12}>
                                                                {this.state.chatIdsWithNewMessages && this.state.chatIdsWithNewMessages.includes(chat.id) && (
                                                                    <span><Badge bg="success">NEW</Badge> </span>
                                                                )}

                                                                {chat.members.length === 1 ? (
                                                                    <span>{chat.members[0].memberName}</span>
                                                                ) : (
                                                                    <>
                                                                        {chat.groupName ? (
                                                                            <span>{chat.groupName}</span>
                                                                        ) : (
                                                                            <>{chat.members.map((member, memberIndex) => (
                                                                                <span key={memberIndex}>{member.memberName}{memberIndex !== chat.members.length - 1 && ', '}</span>
                                                                            ))}
                                                                                {chat.members.length > 40 && '...'}
                                                                            </>
                                                                        )}

                                                                    </>
                                                                )}
                                                            </Col>
                                                            <Col xs={12} className="chatScreen__side__chats__chat__stamp">
                                                                {this.state.messages.length ?
                                                                    (
                                                                        <>
                                                                            {this.state.messages[this.state.messages.length - 1].groupId == chat.id ?
                                                                                (
                                                                                    <>
                                                                                        {this.state.lastMessageSentUserId == this.context.userId ?
                                                                                            (
                                                                                                <span key={this.state.messages.length - 1}>
                                                                                                    You: {this.state.messages[this.state.messages.length - 1].message}<br />
                                                                                                    {formatDateTime(this.state.messages[this.state.messages.length - 1].timestamp)}
                                                                                                </span>
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                <span key={this.state.messages.length - 1}>
                                                                                                    {this.state.messages[this.state.messages.length - 1].user}: {this.state.messages[this.state.messages.length - 1].message}<br />
                                                                                                    {formatDateTime(this.state.messages[this.state.messages.length - 1].timestamp)}
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <span>Start chatting...</span>
                                                                                )
                                                                            }
                                                                        </>

                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            {chat.messages.length > 0 ? (
                                                                                <>
                                                                                    {chat.messages[chat.messages.length - 1].senderId == this.context.userId ?
                                                                                        (
                                                                                            <span>
                                                                                                You: {chat.messages[chat.messages.length - 1].messageText}<br />
                                                                                                {formatDateTime(chat.messages[chat.messages.length - 1].ts)}
                                                                                            </span>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <span>
                                                                                                {chat.messages[chat.messages.length - 1].senderName}: {chat.messages[chat.messages.length - 1].messageText}<br />
                                                                                                {formatDateTime(chat.messages[chat.messages.length - 1].ts)}
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <span>Start chatting...</span>
                                                                            )}
                                                                        </>
                                                                    )
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </a>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={9}>
                            <Row className="chatScreen__main h-100">
                                <Col xs={12} className="chatScreen__main__chatHead">
                                    <div className="p-3 px-4 text-white">
                                        {(() => {
                                            const openChat = this.state.chatData.find(item => item.id === this.state.currentlyOpenChat);
                                            if (openChat) {
                                                return openChat.groupName ? (
                                                    <span>Chatting with {openChat.members[0].memberName}</span>
                                                ) : (
                                                    <span>Chatting with {openChat.members[0].memberName}</span>
                                                );
                                            } else {
                                                return (
                                                    <span>Open a chat to start talking... {this.state.currentlyOpenChat}</span>
                                                );
                                            }
                                        })()}
                                    </div>
                                </Col>
                                <Col xs={12} className="chatScreen__main__chatBody d-flex flex-column overflow-hidden">
                                    <div ref={this.scrollableDivRef} className="chatScreen__main__chatBody__messageArea px-4">
                                        {this.state.currentlyOpenChat && (
                                            <div>
                                                {(() => {
                                                    const openChat = this.state.chatData.find(item => item.id === this.state.currentlyOpenChat);
                                                    if (openChat) {
                                                        return (
                                                            openChat.messages.map((chatMessages, messageIndex) => (
                                                                <div key={messageIndex} className={`my-3 ${chatMessages.senderId === this.context.userId ? 'text-end' : ''}`}>
                                                                    <Row>
                                                                        <Col xs={1}>
                                                                        </Col>
                                                                        <Col xs={11}>
                                                                            {chatMessages.senderId === this.context.userId ? (
                                                                                <div className="chatScreen__main__chatBody__messageSender">
                                                                                    {formatDateTime(chatMessages.ts)}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="chatScreen__main__chatBody__messageReceiver">
                                                                                    {chatMessages.senderName} {formatDateTime(chatMessages.ts)}
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={1}>
                                                                            {chatMessages.senderId === this.context.userId ? (
                                                                                <></>
                                                                            ) : (
                                                                                <div className="chatScreen__main__chatBody__messageSenderProfileImageContainer">
                                                                                    <div className="chatScreen__main__chatBody__messageSenderProfileImage">
                                                                                        <span>{getInitials(chatMessages.senderName)}</span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={11}>
                                                                            {chatMessages.senderId === this.context.userId ? (
                                                                                <div className="chatScreen__main__chatBody__messageBubble userBubble">
                                                                                    {chatMessages.messageText}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="chatScreen__main__chatBody__messageBubble">
                                                                                    {chatMessages.messageText}
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            ))
                                                        );
                                                    }
                                                })()}

                                                {this.state.messages
                                                    .filter(msg => msg.groupId === this.state.activeChat)
                                                    .map((msg, index) => (
                                                    <div key={index} className={`my-3 ${msg.userId === this.context.userId ? 'text-end' : ''}`}>
                                                        <Row>
                                                            <Col xs={1}>
                                                            </Col>
                                                            <Col xs={11}>
                                                                {msg.userId === this.context.userId ? (
                                                                    <div className="chatScreen__main__chatBody__messageSender">
                                                                        {formatDateTime(msg.timestamp)}
                                                                    </div>
                                                                ) : (
                                                                    <div className="chatScreen__main__chatBody__messageReceiver">
                                                                        {msg.user} {formatDateTime(msg.timestamp)}
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={1}>
                                                                {msg.userId === this.context.userId ? (
                                                                    <></>
                                                                ) : (
                                                                    <div className="chatScreen__main__chatBody__messageSenderProfileImageContainer">
                                                                        <div className="chatScreen__main__chatBody__messageSenderProfileImage">
                                                                            <span>{getInitials(msg.user)}</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                            <Col xs={11}>
                                                                {msg.userId === this.context.userId ? (
                                                                    <div className="chatScreen__main__chatBody__messageBubble userBubble">
                                                                        {msg.message}
                                                                    </div>
                                                                ) : (
                                                                    <div className="chatScreen__main__chatBody__messageBubble">
                                                                        {msg.message}
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>


                                                ))}
                                            </div>

                                        )}

                                    </div>
                                </Col>
                                <Col xs={12} className="chatScreen__main__chatFoot">
                                    <div className="message-input p-3">
                                        <Form.Group controlId="submitChatMessage" className="input-group">
                                            <Form.Control
                                                type="text"
                                                placeholder="Type a message..."
                                                value={this.state.newMessage}
                                                onChange={(e) => this.setState({ newMessage: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        this.sendMessage();
                                                    }
                                                }}
                                            />
                                            <Button variant="blue-solid" onClick={this.sendMessage}>
                                                <FontAwesomeIcon icon={faPaperPlane} />
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}

export default Chat;
