import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './stylescompiled/minified/custom.min.css';
import axios from 'axios';
import AuthContext from './components/AuthContext';

class App extends Component {
    constructor(props) {
        super(props);

        const jwtToken = localStorage.getItem('DigitalHubLocal');
        const isLoggedIn = jwtToken != null && jwtToken !== 'null';


        this.state = {
            isLoggedIn: false,
            loggedInName: null,
            userId: 0,
            userType: null,
            isLoading: true
        };
        this.setIsLoggedIn = this.setIsLoggedIn.bind(this);
    }

    handleLogout = () => {
        localStorage.removeItem('DigitalHubLocal');
        this.setState({ isLoggedIn: false });
        this.setState({ loggedInName: null });
        this.setState({ userId: 0 });
        this.setState({ userType: null });
    };

    setIsLoggedIn(value, user, userId, userType) {
        this.setState({
            isLoggedIn: value,
            loggedInName: user,
            userId: userId,
            userType: userType
        });
    }


    componentDidMount() {
        const jwtToken = localStorage.getItem('DigitalHubLocal');

        // Check if the token is already null or the user is already logged in
        if (!jwtToken || jwtToken === 'null') {
            this.setState({ isLoading: false });
            localStorage.removeItem('DigitalHubLocal');
            return;
        }

        // Token verification
        const API_URL = process.env.REACT_APP_API_URL;
        axios.post(`${API_URL}/Auth/authToken`, null, {
            params: {
                token: jwtToken, // Pass the JWT token as a query parameter
            },
        })
            .then(response => {
                // Handle the response from the server after JWT authentication
                if (response.data.authStatus) {
                    this.setIsLoggedIn(true, response.data.authUser, response.data.authUserId, response.data.authUserType);
                } else {
                    this.handleLogout();
                }
                this.setState({ isLoading: false });
            })
            .catch(error => {
                // Handle errors
                this.setState({ isLoading: false });
                this.handleLogout();
                this.setState({ isLoading: false });
            });
    }


    render() {
        const { isLoggedIn, loggedInName, userId, userType } = this.state;

        if (this.state.isLoading) {
            return (
                <Container>
                    <Row>
                        <Col>
                            <h3 className="text-center my-3">Digital Hub is loading... Please wait</h3>
                        </Col>
                    </Row>
                </Container>
            );
        }

        return (
            <AuthContext.Provider value={{ isLoggedIn, loggedInName, userId, userType, setIsLoggedIn: this.setIsLoggedIn, handleLogout: this.handleLogout }}>
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </AuthContext.Provider>
        );
    }
}


export default App;