import React, { Component } from 'react';
import AuthContext from '../AuthContext';
import { Row, Col, Container } from 'react-bootstrap';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';



class HomeDash extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    static contextType = AuthContext;

    render() {
        const { isLoggedIn, loggedInName, userType } = this.context;

        return (
            <Container className="h-100">
                <Row className="justify-content-center align-items-center m-0 h-100">
                    <Col xs={12} className="text-white">
                        <h1 className="mb-0 mt-3">Welcome to the ICE Digital Hub</h1>
                        <p style={{ fontSize: '0.75rem', marginTop: '-0.5rem', paddingLeft: '0.5rem' }}>By ICE Creates</p>
                        <p>
                            We've created a digital hub designed to empower developers, managers, and clients, fostering a collaborative environment that enhances efficient project management.
                        </p>
                    </Col>
                    <Col xs={12} className="text-white">
                        <Row>
                            <Col>
                                <NavLink tag={Link} to="/client/login">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span>DigitalHub for...</span><br />
                                                    <span className="title">Clients</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col>
                                <NavLink tag={Link} to="/ice/login">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span>DigitalHub for...</span><br />
                                                    <span className="title">Managers</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col>
                                <NavLink tag={Link} to="/ice/login">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span>DigitalHub for...</span><br />
                                                    <span className="title">Developers</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default HomeDash;