import React, { Component } from 'react';
import { jwtDecode } from "jwt-decode";
import CryptoJS from 'crypto-js';
import ClientLogin from './ClientLogin';
import axios from 'axios';
import AuthContext from './AuthContext';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';


class Footer extends Component {

    render() {
        const currentYear = new Date().getFullYear()

        return (
            <footer className="py-2">
                <Container>
                    <Row>
                        <Col className="text-center">
                            <span>&copy; ICE Creates Ltd. All rights reserved {currentYear}</span>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}

export default Footer;