import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from '../AuthContext';
import { Form, Button, Row, Col, Container, InputGroup } from 'react-bootstrap';


const Categories = {
    SERVER: 'Server',
    FTP: 'FTP',
    WEBSITE: 'Website',
    CMS: 'CMS',
    PLATFORM: 'Platform'
};

class CreatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allRecords: [],
            loading: false,
            title: '',
            url: '',
            username: '',
            password: '',
            category: '',
            error: '',
            success: ''
        };
    }

    static contextType = AuthContext;

    handleUrlChange = (e) => {
        this.setState({ url: e.target.value });
    };
    handleTitleChange = (e) => {
        this.setState({ title: e.target.value });
    };
    handleCategoryChange = (e) => {
        this.setState({ category: e.target.value });
    };
    handleUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    };
    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const { success, error, title, username, category, password, url } = this.state;
        const { setIsLoggedIn } = this.context;

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${API_URL}/Admin/CreatePassword`, {
                title,
                category,
                username,
                password,
                url
            });
            console.log(response.data);
            if (response.data.result) {
                this.setState({ success: response.data.message });
                this.setState({ error: '' });
            } else {
                this.setState({ success: '' });
                this.setState({ error: response.data.message });
            }
        } catch (error) {
            this.setState({ error: 'An error occurred whilst trying to create a password' + error });
        }
    };

    renderDropdownOptions = () => {
        return Object.entries(Categories).map(([key, value]) => (
            <option key={key} value={key}>{value}</option>
        ));
    }


    render() {
        const { isLoggedIn, loggedInName } = this.context;
        const { loading, allRecords, title, username, password, category, url } = this.state;

        if (!isLoggedIn) {
            window.location.href = '/';
        }


        return (
            <div className="homePage">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={4}>

                                    <h3>Add New Password</h3>

                                    <Form onSubmit={this.handleFormSubmit}>

                                        <Form.Group className="mt-3" controlId="formBasicTitle">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Title"
                                                value={title}
                                                onChange={this.handleTitleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mt-3" controlId="formBasicUsername">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Username"
                                                value={username}
                                                onChange={this.handleUsernameChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mt-3" controlId="formBasicCategory">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Select
                                                value={category}
                                                onChange={this.handleCategoryChange}>
                                                <option value="">Category</option>
                                                {this.renderDropdownOptions()}
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group className="mt-3" controlId="formBasicPassword">
                                            <Form.Label className="mb-0">Password</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Password"
                                                value={password}
                                                onChange={this.handlePasswordChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mt-3" controlId="formBasicUrl">
                                            <Form.Label className="mb-0">URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="URL"
                                                value={url}
                                                onChange={this.handleUrlChange}
                                            />
                                        </Form.Group>

                                        <Button variant="blue-solid" className="mt-3" type="submit">
                                            Create Password
                                        </Button>
                                    </Form>

                                    {this.state.success && <p style={{ color: 'green' }}>{this.state.success}</p>}
                                    {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                                </Col>
                                    <Col xs={8}>

                                        <h3>Edit Records</h3>

                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
            </div>
        );
    }
}

export default CreatePassword;
