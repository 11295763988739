import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from '../AuthContext';
import { Form, Button, Row, Col, Container, InputGroup, FormControl } from 'react-bootstrap';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Chat from '../SignalR/Chat';


class DeveloperDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    static contextType = AuthContext;


    render() {
        const { isLoggedIn, loggedInName, userType } = this.context;

        if (!isLoggedIn || userType != "DEV") {
            window.location.href = '/';
        }

        if (!isLoggedIn) {
            return <div>Checking authentication...</div>;
        }

        return (
            <Container className="py-3">
                <Row className="text-white">
                    <Col xs={12}>
                        <h1>Developer Dashboard</h1>
                    </Col>
                </Row>
                <Row className="text-white justify-content-center">
                    <Col xs={9} md={12}>
                        <Row>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <NavLink tag={Link} to="/clients">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">Clients</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <NavLink tag={Link} to="/servers">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">Servers</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <NavLink tag={Link} to="/passwordVault">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">Password Vault</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <NavLink tag={Link} to="/knowledgeHub">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">Knowledge Hub</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <NavLink tag={Link} to="/chat">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">Live Chat</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <a className="nav-link" href="https://icecreates.atlassian.net/jira/your-work" target="_blank" rel="noopener noreferrer">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">JIRA Board</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </a>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <a className="nav-link" href="https://dev.azure.com/IceCreatesDevOpsPrd/" target="_blank" rel="noopener noreferrer">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">DEV OPS</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </a>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="my-3">
                                <NavLink tag={Link} to="/settings">
                                    <div className="homeBox">
                                        <Row className="h-100 align-items-center">
                                            <Col className="text-center">
                                                <p className="mb-0">
                                                    <span className="title">Settings</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </NavLink>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default DeveloperDash;