import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';
import { Form, Button, Row, Col, Container, InputGroup, FormControl } from 'react-bootstrap';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

class IceLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            showPassword: false,
            error: ''
        };
    }

    static contextType = AuthContext

    toggleShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const { email, password } = this.state;
        const { setIsLoggedIn } = this.context;

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${API_URL}/Auth/iceLogin`, {
                email,
                password,
            }, {
                withCredentials: true
            });
            const { loginResult, loginMessage, loginUser, userId, loginUserType, token } = response.data;
            if (loginResult) {
                localStorage.setItem('DigitalHubLocal', token);
                setIsLoggedIn(true, loginUser, userId, loginUserType);
            } else {
                this.setState({ error: loginMessage });
            }
        } catch (error) {
            this.setState({ error: 'An error occurred during login' });
        }
    };

    render() {
        const { error, email } = this.state;
        const { isLoggedIn } = this.context;

        if (isLoggedIn) {
            window.location.href = '/';
        }
        return (
            <div className="iceLoginPage h-100">
                <Row className="justify-content-center align-items-center m-0 h-100">
                    <Col lg={4} md={6} xs={12}>
                        <Container>
                            <Form onSubmit={this.handleFormSubmit}>

                                <h2 className="text-center">ICE Login</h2>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="mb-0">Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={this.handleEmailChange}
                                    />
                                </Form.Group>

                                <Form.Group className="mt-3" controlId="formBasicPassword">
                                    <Form.Label className="mb-0">Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type={this.state.showPassword ? "text" : "password"}
                                            placeholder="Enter your password"
                                            value={this.state.password}
                                            onChange={this.handlePasswordChange}
                                        />
                                        <InputGroup.Text onClick={this.toggleShowPassword}>
                                            {this.state.showPassword ? <EyeFill /> : <EyeSlashFill />}
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>

                                <Button variant="blue" className="mt-3" type="submit">
                                    ICE Login
                                </Button>

                                {error && <p className="mt-3" style={{ color: 'red' }}>{error}</p>}
                            </Form>
                        </Container>
                    </Col>
                    <Col xs={12}>
                        <Container>
                            <Row>
                                <Col className="text-end">
                                    <p>Are you a client?..</p>
                                    <NavLink tag={Link} to="/client/login"><button className="btn btn-yellow-solid">Client Login</button></NavLink>

                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default IceLogin;