import React, { Component } from 'react';
import axios from 'axios';
import PasswordVaultChild from './PasswordVaultChild';
import { Form, Button, Row, Col, Container, InputGroup, Collapse, Table } from 'react-bootstrap';
import AuthContext from '../AuthContext';

class KnowledgeHub extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { loading } = this.state;
        const { isLoggedIn, loggedInName, userType } = this.context;

        if (!isLoggedIn && userType != "DEV") {
            window.location.href = '/';
        }

        if (!isLoggedIn) {
            return <div>Checking authentication...</div>;
        }

        return (
            <div className="homePage">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                    </>
                )}
            </div>
        );
    }
}

export default KnowledgeHub;
