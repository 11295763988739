import Home from "./components/Home";
import ClientLogin from "./components/ClientLogin";
import IceLogin from "./components/IceLogin";
import CreateUser from "./components/CRUD/CreateUser";
import CreateClient from "./components/CRUD/CreateClient";
import CreatePassword from "./components/CRUD/CreatePassword";
import Err404 from "./components/404";

import AdminDash from "./components/Dashboards/AdminDash";
import ClientDash from "./components/Dashboards/ClientDash";
import ManagerDash from "./components/Dashboards/ManagerDash";
import DeveloperDash from "./components/Dashboards/DeveloperDash";

import IceClientsList from "./components/Ice/ClientsList";
import DeveloperServers from "./components/Developer/Servers";
import PasswordVault from "./components/Developer/PasswordVault";
import KnowledgeHub from "./components/Developer/KnowledgeHub";
import Chat from "./components/SignalR/Chat";

const AppRoutes = [
    {
        index: true,
        exact: true,
        element: <Home />
    },
    {
        path: '/dashboard/admin',
        exact: true,
        element: <AdminDash />
    },
    {
        path: '/dashboard/client',
        exact: true,
        element: <ClientDash />
    },
    {
        path: '/dashboard/manager',
        exact: true,
        element: <ManagerDash />
    },
    {
        path: '/dashboard/developer',
        exact: true,
        element: <DeveloperDash />
    },
    {
        path: '/clients',
        exact: true,
        element: <IceClientsList />
    },
    {
        path: '/servers',
        exact: true,
        element: <DeveloperServers />
    },
    {
        path: '/passwordVault',
        exact: true,
        element: <PasswordVault />
    },
    {
        path: '/knowledgeHub',
        exact: true,
        element: <KnowledgeHub />
    },
    {
        path: '/chat',
        exact: true,
        element: <Chat />
    },
    {
        path: '/admin/createUser',
        exact: true,
        element: <CreateUser />
    },
    {
        path: '/admin/createPassword',
        exact: true,
        element: <CreatePassword />
    },
    {
        path: '/admin/createClient',
        exact: true,
        element: <CreateClient />
    },
    {
        path: '/client/login',
        exact: true,
        element: <ClientLogin />
    },
    {
        path: '/ice/login',
        exact: true,
        element: <IceLogin />
    },
    {
        //Any other path not set above will result in a 404
        path: '*',
        element: <Err404 />
    },
];

export default AppRoutes;
