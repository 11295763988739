import React from 'react';

const AuthContext = React.createContext({
    isLoggedIn: false,
    loggedInName: null,
    userId: 0,
    userType: null,
    setIsLoggedIn: () => { },
});

export default AuthContext;
