import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Footer from './Footer';

class Layout extends Component {

    render() {
        const { children } = this.props;

        return (
            <>
                <NavMenu />
                <main tag="main">
                    {React.Children.map(children, (child) =>
                        React.cloneElement(child)
                    )}
                </main>
                <Footer />
            </>
        );
    }
}

export default Layout;