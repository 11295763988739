import React, { Component } from 'react';
import { jwtDecode } from "jwt-decode";
import CryptoJS from 'crypto-js';
import ClientLogin from './ClientLogin';
import axios from 'axios';
import AuthContext from './AuthContext';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

//Dashboards
import ClientDash from './Dashboards/ClientDash';
import DeveloperDash from './Dashboards/DeveloperDash';
import AdminDash from './Dashboards/AdminDash';
import ManagerDash from './Dashboards/ManagerDash';
import HomeDash from './Dashboards/HomeDash';

class Home extends Component {

    static contextType = AuthContext;

    render() {
        const { isLoggedIn, loggedInName, userType } = this.context;

        let dashboardComponent;

        switch (userType) {
            case "CLIENT":
                dashboardComponent = <ClientDash />;
                break;
            case "DEV":
                dashboardComponent = <DeveloperDash />;
                break;
            case "MANAGER":
                dashboardComponent = <ManagerDash />;
                break;
            case "ADMIN":
                dashboardComponent = <AdminDash />;
                break;
            default:
                dashboardComponent = <HomeDash />; // or null if there's no default
                break;
        }

        return (
            <div className="homePage">
                {dashboardComponent}
            </div>
        );
    }
}

export default Home;