import AppSettings from '../AppSettings';
import AuthContext from './AuthContext';

import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../stylescompiled/minified/NavMenu.min.css';
import LogoutButton from './LogoutButton';

class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }


    static contextType = AuthContext;

    render() {
        const { isLoggedIn, loggedInName, userType } = this.context;
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" container light>
                    <NavbarBrand tag={Link} to="/">{AppSettings.AppName}</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow align-items-center">
                            {isLoggedIn ? (
                                <>
                                    <NavLink tag={Link} className="text-dark p-0 me-md-3 my-2 my-md-0" to="/">
                                        <Button className="btn btn-blue-solid">
                                            Dashboard
                                        </Button>
                                    </NavLink>
                                    <LogoutButton />
                                </>

                            ) : (
                                <>
                                </>
                            )
                            }
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}


export default NavMenu;