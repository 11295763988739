import React, { Component } from 'react';
import axios from 'axios';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import AuthContext from '../AuthContext';
import BackToDash from '../BackToDash';


class ClientsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientNames: []
        };
    }

    static contextType = AuthContext;

    componentDidMount() {
        const API_URL = process.env.REACT_APP_API_URL;
        axios.get(`${API_URL}/Client/getAllClients`)
            .then(response => {
                if (response.data.result) {
                    this.setState({ clientNames: response.data.clientNames });
                }
            })
            .catch(error => {
            });
    }
    render() {
        const { clientNames } = this.state; // Access state here
        const { isLoggedIn } = this.context;

        if (!isLoggedIn && isLoggedIn == false) {
            window.location.href = '/';
        }
        return (
            <div className="homePage">
                <Container className="py-3">
                    <Row className="text-white justify-content-center">
                        <Col xs={9} md={12}>
                            <Row className="text-white">
                                <Col xs={12}>
                                    <h1>Clients List</h1>
                                    <p>Below is a list of all our active clients. Please select a client to see informatiion regarding client projects, support tickets etc.</p>
                                </Col>
                            </Row>
                            <Row>
                            {clientNames.length > 0 ? (
                                clientNames.map((name, index) => (
                                    <Col key={index} xs={12} md={6} lg={3} className="my-3">
                                        <NavLink tag={Link} to={`/clients?name=${encodeURIComponent(name)}`}>
                                            <div className="homeBox">
                                                <Row className="h-100 align-items-center">
                                                    <Col className="text-center">
                                                        <p className="mb-0">
                                                            <span className="title">{name}</span>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </NavLink>
                                    </Col>
                                ))
                            ) : (
                                <div>No clients found</div>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ClientsList;