import React, { Component } from 'react';
import { jwtDecode } from "jwt-decode";
import CryptoJS from 'crypto-js';
import ClientLogin from './ClientLogin';
import axios from 'axios';
import AuthContext from './AuthContext';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

//Dashboards
import ClientDash from './Dashboards/ClientDash';
import DeveloperDash from './Dashboards/DeveloperDash';
import AdminDash from './Dashboards/AdminDash';
import ManagerDash from './Dashboards/ManagerDash';
import HomeDash from './Dashboards/HomeDash';
import BackToDash from './BackToDash';

class Home extends Component {

    static contextType = AuthContext;

    render() {
        return (
            <div className="homePage d-flex align-items-center justify-content-center">
                <div>
                    <h1 className="display-1">404!</h1>
                </div>
            </div>
        );
    }
}

export default Home;