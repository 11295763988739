import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import AuthContext from './AuthContext';


class BackToDash extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    static contextType = AuthContext;

    componentDidMount() {
    }
    render() {
        return (
            <Row className="my-3">
                <Col>
                    <NavLink tag={Link} className="d-inline-block text-dark p-0 me-md-3 my-2 my-md-0" to="/">
                        <Button className="btn btn-yellow-solid">Back to Dashboard</Button>
                    </NavLink>
                </Col>
            </Row>
        );
    }
}

export default BackToDash;