import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../AuthContext';

class PasswordVaultChild extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingCountdown: false,
            passwordProgress: 100
        };
    }
    static contextType = AuthContext;


    startLoading = () => {
        this.setState({ passwordProgress: 100, isLoadingCountdown: true });
        this.interval = setInterval(() => {
            this.setState(prevState => ({ passwordProgress: prevState.passwordProgress - 1 }));
        }, 100);

        setTimeout(() => {
            this.setState({ isLoadingCountdown: false });
            clearInterval(this.interval);
        }, 10000);
    };

    componentWillUnmount() {
        this.setState({ isLoadingCountdown: false });
        clearInterval(this.interval);
    }

    fetchPassword = async (vaultId) => {
        this.props.onCopyButtonClick();
        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${API_URL}/PasswordVault/DecryptPassword`, { Id: vaultId });
            navigator.clipboard.writeText(response.data.password);
            this.startLoading();
            setTimeout(() => {
                navigator.clipboard.writeText('');
            }, 10000);

        } catch (error) {
        }
    };

    render() {
        const { passwordProgress } = this.state;
        const { passwordId, passwordTitle, passwordCategory, passwordUsername, passwordPassword, passwordUrl, isCopying } = this.props;
        const { isLoggedIn, loggedInName, userType } = this.context;

        if (!isLoggedIn && userType != "DEV") {
            window.location.href = '/';
        }

        if (!isLoggedIn) {
            return <div>Checking authentication...</div>;
        }

        return (
            <tr>
                <td>{passwordTitle}</td>
                <td>{passwordUsername}</td>
                <td>{passwordUrl}</td>
                <td>
                    <div className="d-flex align-items-center">
                        <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="tooltip-left">Copy</Tooltip>}
                        >
                            <Button variant="blue-solid" className="py-0 px-1" onClick={() => this.fetchPassword(passwordId)} disabled={isCopying}>
                                <FontAwesomeIcon icon={faCopy} />
                            </Button>
                        </OverlayTrigger>
                        {this.state.isLoadingCountdown ? (
                            <div className="progress ms-1 w-100">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${passwordProgress}%` }}
                                    aria-valuenow={passwordProgress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        ) : (
                            <div className="progress ms-1 w-100">
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `100%` }}
                                    aria-valuenow={passwordProgress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        );
    }
}

export default PasswordVaultChild;
