import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from '../AuthContext';
import { Form, Button, Row, Col, Container, InputGroup, FormControl } from 'react-bootstrap';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';

const UserTypes = {
    ADMIN: 'Admin',
    DEV: 'Developer',
    MANAGER: 'Manager'
};

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            password: '',
            showPassword: false,
            usertype: '',
            error: '',
            success: ''
        };
    }

    static contextType = AuthContext;

    toggleShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    handleUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    };

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
    };


    handleUsertypeChange = (e) => {
        this.setState({ usertype: e.target.value });
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const { email, username, password, passwordShown, usertype } = this.state;
        const { setIsLoggedIn } = this.context;

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${API_URL}/Admin/createUser`, {
                email,
                username,
                password,
                usertype,
                
            });
            const { createResult, createMessage } = response.data;
            if (createResult) {
                this.setState({ success: createMessage });
                this.setState({ error: '' });
            } else {
                this.setState({ success: '' });
                this.setState({ error: createMessage });
            }
        } catch (error) {
            this.setState({ error: 'An error occurred whilst trying to create a user' + error });
        }
    };

    renderDropdownOptions = () => {
        return Object.entries(UserTypes).map(([key, value]) => (
            <option key={key} value={key}>{value}</option>
        ));
    }

    render() {
        const { isLoggedIn, loggedInName, userType } = this.context;
        const { success, error, email, username, usertype, password } = this.state;
        
        if (!isLoggedIn) {
            window.location.href = '/';
        }

        if (!isLoggedIn) {
            return <div>Checking authentication...</div>;
        }

        return (
            <Container>
                <Row className="justify-content-center">
                    <Col md={4}>

                        {error && <p style={{ color: 'red' }}>{error}</p>}

                        <Form onSubmit={this.handleFormSubmit}>

                            <Form.Group className="mt-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="New User Email"
                                    value={email}
                                    onChange={this.handleEmailChange}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3" controlId="formBasicUsername">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="New User Name"
                                    value={username}
                                    onChange={this.handleUsernameChange}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3" controlId="formBasicUserType">
                                <Form.Label>User Type</Form.Label>
                                <Form.Select
                                    value={usertype}
                                    onChange={this.handleUsertypeChange}>
                                    <option value="">Select User Type</option>
                                    {this.renderDropdownOptions()}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mt-3" controlId="formBasicPassword">
                                <Form.Label className="mb-0">Password</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={this.state.showPassword ? "text" : "password"}
                                        placeholder="Enter your password"
                                        value={this.state.password}
                                        onChange={this.handlePasswordChange}
                                    />
                                    <InputGroup.Text onClick={this.toggleShowPassword}>
                                        {this.state.showPassword ? <EyeFill /> : <EyeSlashFill />}
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>

                            <Button variant="blue" className="mt-3" type="submit">
                                Create User
                            </Button>
                        </Form>

                        {success && <p style={{ color: 'green' }}>{success}</p>}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default CreateUser;