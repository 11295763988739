import React, { Component } from 'react';
import axios from 'axios';
import PasswordVaultChild from './PasswordVaultChild';
import { Form, Button, Row, Col, Container, InputGroup, Collapse, Table } from 'react-bootstrap';
import AuthContext from '../AuthContext';

class PasswordVault extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allRecords: [],
            loading: true,
            serverAccordionOpen: false,
            ftpAccordionOpen: false,
            websiteAccordionOpen: false,
            cmsAccordionOpen: false,
            platformAccordionOpen: false,
            isCopying: false
        };
    }

    handleCopyButtonClick = () => {
        this.setState({ isCopying: true });
        setTimeout(() => {
            this.setState({ isCopying: false });
        }, 10000);
    };

    toggleServerAccordion = () => {
        this.setState({ serverAccordionOpen: !this.state.serverAccordionOpen });
    };
    toggleFtpAccordion = () => {
        this.setState({ ftpAccordionOpen: !this.state.ftpAccordionOpen });
    };
    toggleWebsiteAccordion = () => {
        this.setState({ websiteAccordionOpen: !this.state.websiteAccordionOpen });
    };
    toggleCmsAccordion = () => {
        this.setState({ cmsAccordionOpen: !this.state.cmsAccordionOpen });
    };
    togglePlatformAccordion = () => {
        this.setState({ platformAccordionOpen: !this.state.platformAccordionOpen });
    };

    Categories = {
        SERVER: 'SERVER',
        FTP: 'FTP',
        WEBSITE: 'WEBSITE',
        CMS: 'CMS',
        PLATFORM: 'PLATFORM'
    };

    static contextType = AuthContext;

    componentDidMount() {
        this.fetchPasswordVaults();
    }

    fetchPasswordVaults = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await axios.get(`${API_URL}/PasswordVault/GetAllPasswordEntries`);
            this.setState({
                allRecords: response.data.allEntries,
                loading: false
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    };

    render() {
        const { loading, allRecords, serverAccordionOpen, ftpAccordionOpen, websiteAccordionOpen, cmsAccordionOpen, platformAccordionOpen } = this.state;
        const { isLoggedIn, loggedInName, userType } = this.context;

        if (!isLoggedIn && userType != "DEV") {
            window.location.href = '/';
        }

        if (!isLoggedIn) {
            return <div>Checking authentication...</div>;
        }

        return (
            <div className="homePage">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <Container>
                            <Row className="my-4">
                                <Col xs={12}>
                                    <Row>
                                        <Button
                                            onClick={this.toggleServerAccordion}
                                            aria-controls="serveraccordion_content"
                                            aria-expanded={serverAccordionOpen}
                                            variant="blue-solid">
                                            Server Paswords
                                        </Button>
                                        <Collapse in={serverAccordionOpen}>
                                            <div id="serveraccordion_content" className="passwordVaultTab p-3">

                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                                <th>Title</th>
                                                                <th>Username</th>
                                                                <th>URL</th>
                                                                <th>Password</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            {allRecords
                                                                .filter(passwordRecord => passwordRecord.category === this.Categories.SERVER)
                                                                .map(passwordRecord => (
                                                                    <PasswordVaultChild key={passwordRecord.id} passwordId={passwordRecord.id} passwordTitle={passwordRecord.title} passwordCategory={passwordRecord.category} passwordUsername={passwordRecord.username} passwordPassword={passwordRecord.password} passwordUrl={passwordRecord.url} isCopying={this.state.isCopying} onCopyButtonClick={this.handleCopyButtonClick} />
                                                                ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Collapse>
                                    </Row>
                                    <hr className="my-3" />
                                    <Row>
                                        <Button
                                            onClick={this.toggleCmsAccordion}
                                            aria-controls="cmsaccordion_content"
                                            aria-expanded={cmsAccordionOpen}
                                            variant="yellow-solid">
                                            CMS Paswords
                                        </Button>
                                        <Collapse in={cmsAccordionOpen}>
                                                <div id="cmsaccordion_content" className="passwordVaultTab p-3">

                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Username</th>
                                                                <th>URL</th>
                                                                <th>Password</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allRecords
                                                                .filter(passwordRecord => passwordRecord.category === this.Categories.CMS)
                                                                .map(passwordRecord => (
                                                                    <PasswordVaultChild key={passwordRecord.id} passwordId={passwordRecord.id} passwordTitle={passwordRecord.title} passwordCategory={passwordRecord.category} passwordUsername={passwordRecord.username} passwordPassword={passwordRecord.password} passwordUrl={passwordRecord.url} isCopying={this.state.isCopying} onCopyButtonClick={this.handleCopyButtonClick} />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                            </div>
                                        </Collapse>
                                    </Row>
                                    <hr className="my-3" />
                                    <Row>
                                        <Button
                                            onClick={this.toggleFtpAccordion}
                                            aria-controls="ftpaccordion_content"
                                            aria-expanded={ftpAccordionOpen}
                                            variant="blue-solid">
                                            FTP Paswords
                                        </Button>
                                        <Collapse in={ftpAccordionOpen}>
                                                <div id="ftpaccordion_content" className="passwordVaultTab p-3">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Username</th>
                                                                <th>URL</th>
                                                                <th>Password</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allRecords
                                                                .filter(passwordRecord => passwordRecord.category === this.Categories.FTP)
                                                                .map(passwordRecord => (
                                                                    <PasswordVaultChild key={passwordRecord.id} passwordId={passwordRecord.id} passwordTitle={passwordRecord.title} passwordCategory={passwordRecord.category} passwordUsername={passwordRecord.username} passwordPassword={passwordRecord.password} passwordUrl={passwordRecord.url} isCopying={this.state.isCopying} onCopyButtonClick={this.handleCopyButtonClick} />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                            </div>
                                        </Collapse>
                                    </Row>
                                    <hr className="my-3" />
                                    <Row>
                                        <Button
                                            onClick={this.togglePlatformAccordion}
                                            aria-controls="platformaccordion_content"
                                            aria-expanded={platformAccordionOpen}
                                            variant="yellow-solid">
                                            Platform Paswords
                                        </Button>
                                        <Collapse in={platformAccordionOpen}>
                                                <div id="platformaccordion_content" className="passwordVaultTab p-3">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Username</th>
                                                                <th>URL</th>
                                                                <th>Password</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allRecords
                                                                .filter(passwordRecord => passwordRecord.category === this.Categories.PLATFORM)
                                                                .map(passwordRecord => (
                                                                    <PasswordVaultChild key={passwordRecord.id} passwordId={passwordRecord.id} passwordTitle={passwordRecord.title} passwordCategory={passwordRecord.category} passwordUsername={passwordRecord.username} passwordPassword={passwordRecord.password} passwordUrl={passwordRecord.url} isCopying={this.state.isCopying} onCopyButtonClick={this.handleCopyButtonClick} />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                            </div>
                                        </Collapse>
                                    </Row>
                                    <hr className="my-3" />
                                    <Row>
                                        <Button
                                            onClick={this.toggleWebsiteAccordion}
                                            aria-controls="websiteaccordion_content"
                                            aria-expanded={websiteAccordionOpen}
                                            variant="blue-solid">
                                            Website Paswords
                                        </Button>
                                        <Collapse in={websiteAccordionOpen}>
                                                <div id="websiteaccordion_content" className="passwordVaultTab p-3">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Title</th>
                                                                <th>Username</th>
                                                                <th>URL</th>
                                                                <th>Password</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {allRecords
                                                                .filter(passwordRecord => passwordRecord.category === this.Categories.WEBSITE)
                                                                .map(passwordRecord => (
                                                                    <PasswordVaultChild key={passwordRecord.id} passwordId={passwordRecord.id} passwordTitle={passwordRecord.title} passwordCategory={passwordRecord.category} passwordUsername={passwordRecord.username} passwordPassword={passwordRecord.password} passwordUrl={passwordRecord.url} isCopying={this.state.isCopying} onCopyButtonClick={this.handleCopyButtonClick} />
                                                                ))}
                                                        </tbody>
                                                    </Table>
                                            </div>
                                        </Collapse>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
            </div>
        );
    }
}

export default PasswordVault;
