import React, { Component } from 'react';
import axios from 'axios';
import AuthContext from '../AuthContext';
import { Form, Button, Row, Col, Container, InputGroup, FormControl } from 'react-bootstrap';
import { EyeSlashFill, EyeFill } from 'react-bootstrap-icons';



class ManagerDash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            password: '',
            showPassword: false,
            usertype: '',
            error: '',
            success: ''
        };
    }

    static contextType = AuthContext;

    toggleShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };

    handleUsernameChange = (e) => {
        this.setState({ username: e.target.value });
    };

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const { email, username, password, passwordShown, usertype } = this.state;
        const { setIsLoggedIn } = this.context;

    };

    render() {
        const { isLoggedIn, loggedInName, userType } = this.context;
        const { success, error, email, username, password } = this.state;
        
        if (!isLoggedIn || userType != "MANAGER") {
            window.location.href = '/';
        }

        if (!isLoggedIn) {
            return <div>Checking authentication...</div>;
        }

        return (
            <Container>
                <Row className="text-white">
                    <Col>
                        <h1>Manager Dashboard</h1>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ManagerDash;