import * as signalR from '@microsoft/signalr';

export default class SignalRManager {
    constructor(hubUrl) {
        const API_URL = process.env.REACT_APP_API_URL;
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(API_URL+hubUrl)
            .withAutomaticReconnect()
            .build();
    }

    start() {
        return this.connection.start();
    }

    stop() {
        return this.connection.stop();
    }

    on(method, callback) {
        this.connection.on(method, callback);
    }

    off(method, callback) {
        this.connection.off(method, callback);
    }

    invoke(method, ...args) {
        return this.connection.invoke(method, ...args);
    }
}
