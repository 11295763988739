import React, { Component } from 'react';
import AuthContext from './AuthContext';
import { Button } from 'react-bootstrap';

class LogoutButton extends Component {
    static contextType = AuthContext; // Access the context

    logout = () => {
        this.context.handleLogout(); // Call the logout function from context when clicked
    };

    render() {
        return (
            <Button variant="yellow-solid" onClick={this.logout}>Logout</Button>
        );
    }
}

export default LogoutButton;
